
import Textarea from "@/components/Textarea"
import CamerasAutocomplete from "@/components/CamerasAutocomplete"
import RecordingsWidget from "@/components/recordings/widget/RecordingsWidget"
import { mapStores } from "pinia"
import { useCameraStore } from "@/stores/camera"
import { useAccountStore } from "@/stores/account"
import { CameraStatus } from "@evercam/shared/types"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"

export default {
  meta: {
    pageId: AnalyticsEventPageId.EmbeddedRecordings,
  },
  components: {
    RecordingsWidget,
    CamerasAutocomplete,
    Textarea,
  },
  data() {
    return {
      selectedPreAuth: true,
      isSideBar: true,
      selectedMode: false,
      embedded: true,
      userApiId: "API_ID",
      userApiKey: "API_KEY",
      selectedCamera: null,
      hasDelay: false,
      delayUnit: { text: this.$t("content.minutes"), value: "minute" },
      delayUnitItems: [
        { text: this.$t("content.minutes"), value: "minute" },
        { text: this.$t("content.hours"), value: "hour" },
        { text: this.$t("content.days"), value: "day" },
      ],
      delayLength: 10,
    }
  },
  head() {
    return {
      title: "Evercam | Recordings Widget",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useCameraStore, useAccountStore),
    embedCode() {
      if (this.selectedCamera) {
        let preAuth = ""
        if (this.selectedPreAuth) {
          preAuth = `&api_id=${this.userApiId}&api_key=${this.userApiKey}`
        }
        let isPrivate = this.selectedCamera.isPublic === false
        const selectedMode = `&dark_mode=${this.selectedMode}`
        const embedded = `&embedded=${this.embedded}`
        const isSideBar = `&sidebar=${this.isSideBar}`
        const delay = `&d=${this.delayMilliseconds}`

        // eslint-disable-next-line no-useless-escape
        return `<div evercam="snapshot-navigator"></div><script type="text/javascript" src="${window.location.origin}/snapshotNavigator.js?camera=${this.selectedCamera?.id}${delay}&private=${isPrivate}${preAuth}${isSideBar}${selectedMode}${embedded}"><\/script>`
      }

      return ""
    },
    isCameraDecommissioned(): boolean {
      return this.selectedCamera?.status === CameraStatus.Decommissioned
    },
    cameraId() {
      return `${(this.selectedCamera || this.getDefaultCamera())?.id}`
    },
    isPublicCamera() {
      return this.selectedCamera.isPublic
    },
    delayMilliseconds() {
      if (!this.hasDelay) {
        return 0
      }

      return this.$moment
        .duration(this.delayLength, this.delayUnit.value)
        .asMilliseconds()
    },
  },
  watch: {
    selectedCamera() {
      this.updatePreAuthStatus()
    },
    isCameraDecommissioned: {
      immediate: true,
      handler(value) {
        if (value) {
          this.hasDelay = false
        }
      },
    },
  },
  created() {
    this.selectedCamera = this.getDefaultCamera()
  },
  async mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)

    if (this.$permissions.user.is.admin()) {
      return
    }

    if (!this.accountStore.apiId || !this.accountStore.apiKey) {
      await this.accountStore.fetchUserCredentials()
    }
    this.updatePreAuthStatus()
    this.userApiId = this.accountStore.apiId
    this.userApiKey = this.accountStore.apiKey
  },
  methods: {
    getDefaultCamera() {
      return this.cameraStore.cameras?.[0] || {}
    },
    updatePreAuthStatus() {
      this.selectedPreAuth = !this.isPublicCamera
    },
  },
}
